import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import NonePage from "../../pages/404/404";
import PlugPage from "../../pages/plug/plug";

const MainSwitch = () => {
  return (
    <Routes>
      <Route exact path="/" element={<PlugPage />} />
      {/*<Route path="/" element={<Navigate replace to="/" />} />*/}
      <Route path="*" element={<NonePage />} />
    </Routes>
  );
};

export default MainSwitch;
