import React from 'react';
import './css/main.css';

import Header from './components/base/Header';
import Footer from './components/base/Footer';
import MainSwitch from './components/base/MainSwitch';
import ArrowUp from "./components/base/arrowUp";


class App extends React.Component {
	render() {
		return (
			<div className='app'>
				<Header></Header>
				<div className='box'>
					<MainSwitch></MainSwitch>
				</div>
				<Footer></Footer>
				<ArrowUp/>
			</div>
		);
	}
}

export default App;
