import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.svg";
import sandwich from "../../img/sandwich.svg";
import close from "../../img/close.svg";
import user from "../../img/user.svg";
import logout from "../../img/logout.svg";
import bridge from "../../img/venomBridge.svg";
import web3 from "../../img/web3.svg";
// import imgSuccess from '../../img/success.svg';
import Modal from "./modal";

function Header() {
  const [menuMobile, menuMobileCount] = useState(false);
  const [auth, authState] = useState(true);
  const [userInfo, userInfoState] = useState(false);
  const [isModal, setModal] = useState(false);

  function menuOpen() {
    if (!menuMobile) {
      menuMobileCount(true);
    } else {
      menuMobileCount(false);
    }
  }

  function userInfoOpen() {
    userInfoState(true);
  }

  function userInfoClose() {
    userInfoState(false);
  }

  function logOut() {
    authState(false);
  }

  return (
    <header className="header">
      <div className="header__wrap container">
        <Link to={`/`} className="header__logo">
          <img src={logo} />
        </Link>
      </div>
    </header>
  );
}

export default Header;
