import React from "react";
import { Link } from "react-router-dom";

import imgWhy from "../../img/why.svg";
import imgHow from "../../img/how.svg";
import map from "../../img/map.svg";
import sTw from "../../img/tw.svg";
import sDiscord from "../../img/discord.svg";

class PlugPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="plugPage">
        <div className="container">
          <section className="main-block">
            <h1>
              <p>Get early access</p> to the <span>ideas</span> <br />
              of tomorrow
            </h1>
            <p className="main-block__desc">
              <b>Highly-vetted ideas</b> and teams you can trust. Supported by
              industry-leading creators and funds.
            </p>
          </section>

          <section className="why-block why-block--custom">
            <div className="why-block__list">
              <div className="why-block__item">
                <h2 className="why-block__title">Venom</h2>
                <div className={"why-block__main"}>
                  <h3>testnet campaign</h3>
                  <div className={"why-block__group"}>
                    <a
                      href={"https://venom.network/"}
                      target="_blank"
                      referrerPolicy="no-referrer"
                      className="btn"
                    >
                      <span>More information</span>
                    </a>
                  </div>
                  <p>
                    Venom invites all active Web 3.0 users to take part in
                    testing the network and products developed on the blockchain
                  </p>
                </div>
                <div className="why-block__side">
                  <img src={imgHow} alt="how" />
                </div>
              </div>
              <div className="why-block__item">
                <h2 className="why-block__title">Join</h2>
                <div className={"why-block__main"}>
                  <h3>our social networks</h3>
                  <div className={"why-block__group"}>
                    <div className="social">
                      <a href="https://twitter.com/VenomPad" target="_blank">
                        <img src={sTw} alt="twitter" />
                      </a>
                      <a
                        href="https://discord.com/invite/venompad"
                        target="_blank"
                      >
                        <img src={sDiscord} alt="discord" />
                      </a>
                    </div>
                  </div>
                  <p>
                    Follow our news and be the first to know about new projects
                    on Venom
                  </p>
                </div>
                <div className="why-block__side">
                  <img src={imgWhy} alt="why" />
                </div>
              </div>
            </div>
          </section>

          <section className="start-you">
            <div className="start-you__main">
              <h2>Start your</h2>
              <h3>project on the launchpad</h3>
            </div>
            <img src={map} alt="map" />
            <div className="start-you__side">
              <p>
                Dominate the city in this revolutionary Play2Earn NFT metaverse
                with your crime syndicate and become the Boss of all Bosses.
              </p>
              <a
                href={
                  "https://docs.google.com/forms/u/2/d/e/1FAIpQLSfsLB6evPcmrGHVB7jzgcAE50N3K4FP44ueCOOegio0lEWgwQ/viewform?usp=sf_link"
                }
                className="btn"
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <span>Start your project</span>
              </a>
            </div>
          </section>
        </div>
        <div className="decor1"></div>
      </div>
    );
  }
}

export default PlugPage;
