import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.svg";
import logoMini from "../../img/logo-mini.svg";
import sMedium from "../../img/medium.svg";
import sTw from "../../img/tw.svg";
import sTg from "../../img/tg.svg";
import sDiscord from "../../img/discord.svg";

function Footer() {
  function toggleList(e) {
    e.target.parentNode.classList.toggle("open");
  }

  return (
    <footer className="footer">
      <div className="footer__wrap container">
        <Link to={`/`} className="footer__logo">
          <img src={logo} />
        </Link>
        <nav className="footer__nav"></nav>
        <div className="footer__bottom">
          <div className="footer__social">
            <a href="https://twitter.com/VenomPad" target="_blank">
              <img src={sTw} alt="twitter" />
            </a>
            <a href="https://discord.com/invite/venompad" target="_blank">
              <img src={sDiscord} alt="discord" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
