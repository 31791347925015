import React from "react";
import noneImg from "../../img/404.svg";
import {Link} from "react-router-dom";

class NonePage extends React.Component {
    render() {
        return (
            <div className='none-page'>
                <img src={noneImg} alt="none page"/>
                <div className='none-page__main'>
                    <h1>Page not found</h1>
                    <p>Something seems to have gone wrong. The page you requested does not exist</p>
                    <Link to={'/'} className='btn'><span>Return to main</span></Link>
                </div>
            </div>
        )
    }
}

export default NonePage;