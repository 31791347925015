import React, {useState} from 'react';
import arrow from '../../img/arrow-white.svg';

const ArrowUp = () => {
    const [isArrowUp, setArrowUp] = useState(false);

    document.addEventListener('scroll', function (){
        if(document.documentElement.clientHeight < (window.pageYOffset + (document.documentElement.clientHeight * 0.8))) {
            setArrowUp(true);
        } else {
            setArrowUp(false);
        }
    });

    function scrollTop () {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    return isArrowUp ? (
        <button type='button' className="arrow-up" onClick={scrollTop}>
            <img src={arrow} alt=""/>
        </button>
    ) : null;
};

export default ArrowUp;